import firebase from 'gatsby-plugin-firebase';
import { Resume } from '../../types/Resume';
import { ResumeDatabaseObject } from '../../types/ResumeDatabaseObject';

const revertToResumeVersion = async (
  resumeId: string,
  revertVersionId: string,
) => {
  try {
    // Get db object
    const snapshot = await firebase
      .database()
      .ref(`resumes/${resumeId}`)
      .once('value');
    const resumeObj: ResumeDatabaseObject = snapshot.val();

    const allVersions: { [versionId: string]: Resume } = resumeObj.versions;
    const revertVersion: Resume = resumeObj.versions[revertVersionId];
    const revertTimestamp: number = revertVersion.updatedAt
      ? revertVersion.updatedAt
      : revertVersion.createdAt;

    const updatedVersions = Object.entries(allVersions).reduce(
      (updated, current) => {
        const versionNum: string = current[0];
        const version: Resume = current[1];
        const currTimestamp: number = version.updatedAt
          ? version.updatedAt
          : version.createdAt;
        if (currTimestamp <= revertTimestamp) {
          updated[versionNum] = version;
        }
        return updated;
      },
      {} as ResumeDatabaseObject['versions'],
    );

    await firebase
      .database()
      .ref(`resumes/${resumeId}`)
      .update({
        ...resumeObj,
        latestVersion: revertVersionId,
        versions: updatedVersions,
      });
  } catch (error) {
    return null;
  }
};

export default revertToResumeVersion;

import useDispatch from './useAppDispatch';
import useSelector from './useAppSelector';

import getResumeFirebase from '../api/resume/getLatestResume';
import getResumeVersionsFirebase from '../api/resume/getAllVersions';
import deleteResumeFirebase from '../api/resume/deleteResume';
import deleteTimelineEntries from '../api/timeline/deleteTimelineEntries';
import duplicateResume from '../api/resume/duplicateResume';
import getResumeSharingFirebase from '../api/resume/getResumeSharing';

import {
  selectResume,
  setResume,
  selectResumeUpdatedAt,
  selectResumePreview,
  selectVersionId,
  selectResumeIsPublic,
  selectResumeIsUpdating,
  setIsPublic,
  resumeOnCreate,
} from '../redux/slices/resumeSlice';
import revertResumeVersionsFirebase from '../api/resume/revertToResumeVersion';
import { Resume } from '../types/Resume';
import useUser from './useUser';

export default function useResume() {
  const dispatch = useDispatch();
  const resume = useSelector(selectResume);
  const resumeUpdatedAt = useSelector(selectResumeUpdatedAt);
  const previewResume = useSelector(selectResumePreview);
  const resumeVersionId = useSelector(selectVersionId);
  const resumeIsPublic = useSelector(selectResumeIsPublic);
  const resumeIsUpdating = useSelector(selectResumeIsUpdating);
  const { user } = useUser();

  const getLatestResume = async (id: string) => {
    const newResume = await getResumeFirebase(id);
    if (newResume == null) {
      return;
    }

    dispatch(setResume({ resume: newResume }));
    return newResume;
  };

  const updateResume = async (resumeUpdated: Resume) => {
    dispatch(setResume({ resume: resumeUpdated }));
  };

  const createResume = async (args: { name: string }) => {
    if (user == null) {
      return;
    }
    const { name } = args;
    dispatch(resumeOnCreate({ name, user }));
  };

  const getAllResumeVersions = async (id: string) => {
    if (id == null) {
      return;
    }
    const versions = await getResumeVersionsFirebase(id);
    return versions;
  };

  const revertResumeVersion = async (
    resumeId: string,
    revertVersionId: string,
  ) => {
    if (resumeId == null || revertVersionId == null) {
      return;
    }
    await revertResumeVersionsFirebase(resumeId, revertVersionId);
  };

  const getResumeSharing = async (id: string) => {
    if (id == null) {
      return;
    }
    const resumeIsPublicDatabase = await getResumeSharingFirebase(id);

    dispatch(setIsPublic({ isPublic: resumeIsPublicDatabase ?? false }));
    return resumeIsPublicDatabase;
  };

  const deleteResume = async (resourceId: string) => {
    if (user == null || resourceId == null) {
      return;
    }
    deleteResumeFirebase(resourceId);
    deleteTimelineEntries({ userId: user?.uid, resourceId });
  };

  return {
    getLatestResume,
    updateResume,
    deleteResume,
    createResume,
    duplicateResume,
    getAllResumeVersions,
    getResumeSharing,
    previewResume,
    revertResumeVersion,
    resume,
    resumeUpdatedAt,
    resumeVersionId,
    resumeIsPublic,
    resumeIsUpdating,
  };
}

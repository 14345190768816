import firebase from 'gatsby-plugin-firebase';
import uuid from '../../utils/uuid';
import { getUnsplashPhoto } from '../../utils';

import { Resume } from '../../types/Resume';

const duplicateResume = async (originalResume: Resume): Promise<void> => {
  const id = uuid();
  const versionId = uuid();
  const preview = await getUnsplashPhoto();
  const createdAt = firebase.database.ServerValue.TIMESTAMP;

  const resume = {
    ...originalResume,
    id,
    name: `${originalResume.name} Copy`,
    preview,
    createdAt,
    versionId,
  };

  firebase.database().ref(`resumes/${id}/versions/${versionId}`).set(resume);
};

export default duplicateResume;

import firebase from 'gatsby-plugin-firebase';
import { ResumeDatabaseObject } from '../../types/ResumeDatabaseObject';

const getAllVersions = async (
  id: string,
): Promise<ResumeDatabaseObject | null> => {
  try {
    const snapshot = await firebase
      .database()
      .ref(`resumes/${id}`)
      .once('value');
    const resumeObj: ResumeDatabaseObject = snapshot.val();
    return resumeObj;
  } catch (error) {
    return null;
  }
};

export default getAllVersions;
